<template>
  <div class="index">
    <div id="left" class="content">
      <div class="title">基础信息</div>
      <div class="layout mb-20">
        <div class="label">
          <span class="required">*</span>
          主题封面
        </div>
        <div class="value">
          <selectMaterial :data="add.cover" :allow="['image']" type="single" @change="coverChange" />
          <div class="tip mt-10">建议尺寸900px*500px；大小不超过2M；格式支持JPG、PNG。</div>
        </div>
      </div>

      <div class="layout">
        <div class="label">
          <span class="required">*</span>
          主题名称
        </div>
        <div class="value">
          <BjInput v-model="add.name" style="width: 600px" placeholder="请输入主题名称" />
        </div>
      </div>

      <div class="layout">
        <div class="label">发布状态</div>
        <div class="value">
          <BjSwitch v-model="add.status" class="mb-10" />
          <div class="tip">发布之后，前端将展现此图谱主题。</div>
        </div>
      </div>
    </div>

    <div class="footer" :style="{ width: footWidth }">
      <BjButton type="primary" @click="onSave()">
        <i class="ri-save-line" />
        立即保存
      </BjButton>
    </div>
  </div>
</template>

<script>
import selectMaterial from '@/components/selectMaterial'
import { resourceMapService } from '@/service'

const service = new resourceMapService()

export default {
  name: 'theme',
  components: {
    selectMaterial,
  },

  data() {
    return {
      footWidth: '800px',
      add: {
        name: '', // 主题名称
        cover: [], // 主题封面
        status: 1, // 状态 0-未发布 1-已发布
      },
    }
  },
  mounted() {
    this.footWidth = document.getElementById('left').offsetWidth + 'px'
    window.onresize = () => {
      this.footWidth = document.getElementById('left').offsetWidth + 'px'
    }
  },
  created() {
    if (this.$route.query.id) {
      this.getInfo()
    }
  },
  methods: {
    async getInfo() {
      const { data } = await service.getTheme({
        id: this.$route.query.id,
      })
      this.add.name = data.name
      this.add.status = data.status
      this.add.cover = [{ cover: data.cover }]
    },
    coverChange(data) {
      this.add.cover = data
    },
    async onSave() {
      if (this.$route.query.id) {
        await service.themeEdit({
          id: this.$route.query.id,
          name: this.add.name,
          cover: this.add.cover[0].cover,
          status: this.add.status,
        })
        this.$message.success('编辑成功')
        this.$router.push({
          name: 'resourcesMaptheme',
        })
      } else {
        await service.themeAdd({
          name: this.add.name,
          cover: this.add.cover[0].cover,
          status: this.add.status,
        })
        this.$message.success('添加成功')
        this.$router.push({
          name: 'resourcesMaptheme',
        })
      }
    },
  },
}
</script>

<style lang="less" scoped>
.content {
  background: #fff;
  padding: 20px;
  .title {
    font-size: 16px;
    margin-bottom: 20px;
  }
}

.footer {
  position: fixed;
  bottom: 0;
  z-index: 99;
  display: flex;
  width: 880px;
  height: 60px;
  padding: 0 20px;
  line-height: 60px;
  background: #fff;
  display: flex;
  align-items: center;
}
</style>
